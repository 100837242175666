import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"

import { cn } from "@/lib/utils"
import { X } from "lucide-react"
import { showFlag } from "../form-modals/level"

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
      className
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-transparent data-[state=active]:font-semibold data-[state=active]:text-active-bg data-[state=inactive]:text-text data-[state=inactive]:font-semibold data-[state=active]:shadow-sm data-[state=active]:border-b-ol-success",
      className
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

const TabsComponent = ( tabHeaders: string[], tabBody:React.ReactNode[] ) => (
  <Tabs defaultValue={tabHeaders[0]} className="w-full">
    <TabsList className="bg-transparent border-b border-white rounded-none w-full flex justify-start overflow-x-scroll overflow-y-hidden no-scrollbar">
      {tabHeaders.map((eachHeader) => {return <TabsTrigger key={eachHeader} value={eachHeader} className='focus:outline-none'>{eachHeader}</TabsTrigger> })}
    </TabsList>
    {tabBody.map((eachBodyContent, index) => {
      return(
        eachBodyContent && <TabsContent key={index} value={tabHeaders[index]} className='p-1 md:p-3'>
          {eachBodyContent}
        </TabsContent>    
      )
    })}
  </Tabs>
)

const LocaleTabsComponent = ( 
  tabHeaders: {
    name: string;
    code: string;
    removeTranslation: () => void
  }[],
    tabBody:React.ReactNode[] 
  ) => (
  <Tabs defaultValue={tabHeaders[0].name} className="w-full">
    <TabsList className="bg-transparent border-b border-ol-tertiary rounded-none w-full flex justify-start overflow-x-scroll overflow-y-hidden no-scrollbar">
      {tabHeaders.map((eachHeader) => {
        return  <TabsTrigger key={eachHeader.name} value={eachHeader.name} className='focus:outline-none data-[state=active]:bg-ol-secondary'>
              <div className="flex items-center">
              {eachHeader.name}
              {showFlag(eachHeader.code)}
              {tabHeaders.length > 1 && <X className="text-red-500 w-[20px]" onClick={() => 
                eachHeader.removeTranslation()
                } />}
              </div>
          </TabsTrigger> 
        })
      }
    </TabsList>
    {tabBody.map((eachBodyContent, index) => {
      return(
        eachBodyContent && <TabsContent key={index} value={tabHeaders[index].name} className='p-1 md:p-3'>
          {eachBodyContent}
        </TabsContent>    
      )
    })}
  </Tabs>
)

export { Tabs, TabsList, TabsTrigger, TabsContent, TabsComponent, LocaleTabsComponent }
