import { ReactNode, useEffect } from "react";
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    posthog?: {
      _i: Array<unknown>;
      init: (key: string, options: { api_host: string, person_profiles: string, capture_pageview: boolean }) => void;
      identify: (key: string, options: { email: string }) => void;
      reset: () => void;
    };
    __SV?: number;
  }
}

if (typeof window !== 'undefined') {
  // if (!window.location.href.includes('localhost')) {
  const posthogKey: string = import.meta.env.VITE_APP_POSTHOG_KEY || '';
  const posthogApiHost: string = import.meta.env.VITE_APP_POSTHOG_URL || '';
    if (!window.posthog) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (function (t: Document, e: any) {
        let o: string[], n: number, p: HTMLScriptElement, r: HTMLElement;
        e.__SV ||
          ((window.posthog = e),
            (e._i = []),
            (e.init = function (i: string, s: { api_host: string }, a?: string) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              function g(t: any, e: string) {
                const o = e.split('.');
                if (o.length === 2) {
                  t = t[o[0]];
                  e = o[1];
                }
                t[e] = function () {
                  // eslint-disable-next-line prefer-rest-params
                  t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
              }
              p = t.createElement('script');
              p.type = 'text/javascript';
              p.async = true;
              p.src = `${s.api_host}/static/array.js`;
              r = t.getElementsByTagName('script')[0].parentNode as HTMLElement;
              r.insertBefore(p, t.getElementsByTagName('script')[0]);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              let u: any = e;
              if (typeof a !== 'undefined') {
                u = e[a] = [];
              } else {
                a = 'posthog';
              }
              u.people = u.people || [];
              u.toString = function (t?: number) {
                let e = 'posthog';
                if (a !== 'posthog') {
                  e += `.${a}`;
                }
                if (!t) {
                  e += ' (stub)';
                }
                return e;
              };
              u.people.toString = function () {
                return u.toString(1) + '.people (stub)';
              };
              o = 'capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags'.split(
                ' '
              );
              for (n = 0; n < o.length; n++) g(u, o[n]);
              e._i.push([i, s, a]);
            }),
            (e.__SV = 1));
      })(document, window.posthog || []);

      // Initialize with environment variables
      if (import.meta.env.VITE_APP_POSTHOG_KEY) {
        window.posthog?.init(posthogKey, { 
          api_host: posthogApiHost,
          person_profiles: 'identified_only',
          capture_pageview: false,
        });
      }
    }
  // }
}

export default function PHProvider({children}: {children: ReactNode}){
  // const maxPercentage  = useRef(0)
  // const maxPixels  = useRef(0)
  // useEffect(() => {
  //   function handleScroll(){
  //     const lastPercentage = Math.min(1, (window.innerHeight + window.pageYOffset) / document.body.offsetHeight)
  //     const lastPixel = window.innerHeight + window.pageYOffset

  //     if(lastPercentage > maxPercentage.current){
  //       maxPercentage.current = lastPercentage
  //     }
  //     if(lastPixel > maxPixels.current){
  //       maxPixels.current = lastPixel
  //     }
  //   }
  //   const handlePageLeave = () => {
  //     posthog.capture('$pageLeave', {
  //       'max scroll percentage': maxPercentage.current,
  //       'max scroll pixels': maxPixels.current,
  //       'last scroll percentage': Math.min(1, (window.innerHeight + window.pageYOffset) / document.body.offsetHeight),
  //       'last scroll pixels': window.innerHeight + window.pageYOffset,
  //       'scrolled': maxPixels.current > 0
  //     })
  //   }
  //   window.addEventListener('scroll', handleScroll)
  //   window.addEventListener('beforeunload', handlePageLeave)
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //     window.removeEventListener('beforeunload', handlePageLeave)
  //   }
  // }, [])

  const viteEnabled = import.meta.env.VITE_APP_POSTHOG_KEY;

  if (viteEnabled) {
    return (
      <PostHogProvider client={posthog}>
        {children}
        {/* <PostHogPageView/> */}
      </PostHogProvider>
    )
  } else {
    return <>{children}</>
  }
}

export function PostHogPageView() {
  const location = useLocation();

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture(
        '$pageview',
        {
          '$current_url': window.location.href,
        }
      )
    }
  }, [location])

  return null
}