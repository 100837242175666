import { useUserStore } from '@/store/user';
import { MenuIcon, SunMoon } from 'lucide-react';
import { FC } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
// import LectureModal from './lecture-modal';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Link } from 'react-router-dom';
import { toggleDarkMode } from '@/main';
import { Button } from '@/components/ui/button';
import { Localization } from '@/components/ui/localization-menu';

const Navbar: FC = () => {
  const { t } = useTranslation()
  const { currentUser } = useUserStore();
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [codeSelected, setCodeSelected] = useState('')

  const [
    logoutT,
    ,
    userRole
  ] = [
    'logout',
    'searchCourse',
    `${currentUser?.role}`
  ].map(key => get(t('navBar'), key, ''));

  // function handleSubmit(e: FormEvent) {
  //   e.preventDefault();
  //   const params = new URLSearchParams();
  //   params.append('q', search);

  //   navigate(`/courses?${params.toString()}`);
  // }
  // const setIsOpen = (val:boolean) => {
  //   setModalIsOpen(val)
  // }

  const MyAccount = () => {
    return <> {currentUser && (    
      <div className="flex items-center gap-x-3">
        <div className="w-[40px] h-[40px] border-2 border-gray-400 rounded-full relative overflow-hidden">
          <Avatar className="object-cover object-center w-full h-full">
            <AvatarImage src={currentUser.profilePic} alt="profile pic" />
            <AvatarFallback className="bg-transparent">
              <img
                src="user-icon-placeholder-1.png"
                alt=""
                loading="lazy"
              />
            </AvatarFallback>
          </Avatar>
        </div>
        <div>
          <p className="font-medium leading-[17px]">{currentUser.name}</p>
          <p className="text-gray-400 font-medium text-sm capitalize">
            {userRole}
          </p>
        </div>
      </div>
    )}
    </>
  }

  const LogoutUser = () => {
    return (
      <Link
        className="font-semibold duration-200 transition-all hover:text-hover-color border-0 wl-16"
        type="button"
        to="/logout">
        {logoutT}
      </Link>
    )
  }
  

  return (
    <div className="w-full py-2 px-3 shadow-sm shadow-current" >
      <div className="flex justify-between items-center">
        <div id="search-bar"></div>

        <div className='lg:hidden flex items-center ml-5'>
          <DropdownMenu>
            <DropdownMenuTrigger className='outline-none focus:outline-none focus:border-0'><MenuIcon color='#868686' size={35} /></DropdownMenuTrigger>
            <DropdownMenuContent className='bg-ol-secondary-50 text-white border-0 p-3'>
              <DropdownMenuLabel><MyAccount /></DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem><Localization /></DropdownMenuItem>
              <DropdownMenuItem><LogoutUser /></DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        
        <div className="hidden lg:flex items-center gap-x-10">
          <Button variant="navbar"><Localization /></Button>
          <Button variant="navbar"><SunMoon onClick={toggleDarkMode} /></Button>
          <MyAccount />
          <Button variant="navbar"><LogoutUser /></Button>
        </div>
      </div>
      {/* <LectureModal setIsOpen={setIsOpen} codeSelected={codeSelected} modalIsOpen={modalIsOpen}/> */}
    </div>
  );
};

export default Navbar;
