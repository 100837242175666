import { Globe } from 'lucide-react'
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"


const Localization = () => {
  const { i18n } = useTranslation()

  const getLanguageName = () => {
    switch(i18n.language){
      case('en'):
        return "English";
        break;
      case('zh'):
        return "繁體中文"
        break;
      case('ch'):
        return "简体中文"
        break;
    }
  }
  
  return <DropdownMenu>
    <DropdownMenuTrigger className="focus:outline-0 border-0">
      <Globe size={25} className='hidden lg:block' />
      <p className='font-semibold text-gray-500 lg:hidden'>Language - {getLanguageName()}</p>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="mt-3 bg-secondary shadow-lg border-0 z-50">
      {[
        {
          buttonName: 'English',
          buttonCode: 'en',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            console.log(code)
            console.log()
            localStorage.setItem('i18nextLng', code);
          }
        },
        {
          buttonName: '繁體中文 (Traditional)',
          buttonCode: 'zh',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            localStorage.setItem('i18nextLng', code);
          }
        },
        {
          buttonName: '简体中文 (Simplified)',
          buttonCode: 'ch',
          buttonFn: (code: string) => {
            // if(window.location.href.includes('/content/')){
            //   setModalIsOpen(true)
            //   setCodeSelected(code)
            //   return
            // }
            i18n.changeLanguage(code)
            console.log(code)
            localStorage.setItem('i18nextLng', code);
          }
        },
      ].map((eachBtn, index) => {
        return (
          <DropdownMenuItem
            key={index}>
            <button
              className={`py-3 border-0 focus:outline-0 hover:opacity-60 flex items-center gap-x-3 justify-start w-full ${i18n.language === eachBtn.buttonCode ? "text-accent hover:text-text" : null}`}
              onClick={() => eachBtn.buttonFn(eachBtn.buttonCode)}
              type="button"
            >
              <span className=''>{eachBtn.buttonName}</span>
            </button>
          </DropdownMenuItem>
        )
      })}
    </DropdownMenuContent>
  </DropdownMenu>
}

export {Localization}