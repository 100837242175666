import { Search } from 'lucide-react'
import { FC } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

type Props = {
  setSearch: (val: string) => void
}

const SearchBar:FC<Props> = ({ setSearch }) => {

  const { t } = useTranslation()

  const [
    searchCourseT
  ] = [
    'searchCourse'
    ].map((key) => get(t('navBar'), key, ''));
  return (
    <div className="lg:w-[400px] flex gap-x-3 items-center justify-start border border-[#3b3d46] px-4 py-2 rounded-lg bg-primary">
      <Search size={18} />
      <input
        className="bg-transparent outline-none border-0 w-full"
        placeholder={searchCourseT}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
      />
    </div >
  
  )
}

export default SearchBar