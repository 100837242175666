import { get } from 'lodash'
import { BookCopy } from 'lucide-react'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet, useSearchParams } from 'react-router-dom'
import cx from 'classnames'

const HomePageLayout: FC = () => {
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if(!searchParams.get('cardVal')){
      setSearchParams({cardVal: 'full'})
    }
  },[searchParams, setSearchParams])
  
  const [
    enrolledCoursesT, 
    allCoursesT,
    featuredT
  ] = [
      'enrolledCourses',
      'allCourses',
      'featured'
    ].map((key) => get(t('enrolledCoursesPage'), key, ''));

  return (
    <>
      <div className="flex items-center justify-start space-x-3 border-b border-ol-primary py-2 text-nowrap mt-8 lg:mt-0 overflow-x-scroll no-scrollbar">
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-primary px-3 py-1 flex items-center gap-x-2", {
            'bg-active-bg text-active-color': isActive
          })}
          to={`/?cardVal=${searchParams.get('cardVal')}`}>
          <BookCopy size={20} />
          <span className="font-semibold">{allCoursesT}</span>
        </NavLink>
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-primary px-3 py-1 flex items-center gap-x-2", {
            'bg-active-bg text-active-color': isActive
          })}
          to={`/enrolled?cardVal=${searchParams.get('cardVal')}`}>
          <BookCopy size={20} />
          <span className="font-semibold">{enrolledCoursesT}</span>
        </NavLink>
        <NavLink 
          className={({ isActive}) => cx("border rounded-md border-ol-primary px-3 py-1 flex items-center gap-x-2", {
            'bg-active-bg text-active-color': isActive
          })}
          to={`/featured?cardVal=${searchParams.get('cardVal')}`}>
          <BookCopy size={20} />
          <span className="font-semibold">{featuredT}</span>
        </NavLink>
      </div>
      <Outlet />
    </>
  )
}

export default HomePageLayout