import { get } from "lodash";
import { FC, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useGetSectionsByCourse } from "@/hooks/section";
import { Collapsible, CollapsibleContent } from "@radix-ui/react-collapsible";
import { Badge, ChevronDown, ChevronRight } from "lucide-react";
import { Section } from "@/types/section";
import { Lecture } from "@/types/course";
import { useTranslation } from "react-i18next";
import { useUserStore } from "@/store/user";
import { Button } from "../button";
import Loading from "@/components/loading";

interface MaterialsTabProps {
  section: string
}


const downloadableContent = (content, type, contentType, downloadT) => {
  if(type === 'assignment'){
    return
  }
  return (
    <div className="md:flex justify-between my-2 text-text">
      <p className="p-2 break-words overflow-hidden text-text">
        <span className="font-bold capitalize text-text">
          {contentType}:
        </span>
        {' '} - {content.title} - {type.length > 0 ? content[type]?.name : content?.name}
      </p>
      <Button className="ml-2 md:ml-0 mr-4 bg-active-bg hover:bg-active-bg py-0"
      onClick={() => type.length > 0 ? window.open(content[type].url) : window.open(content.url)}
      >
        {downloadT}
      </Button>
    </div>
  )
}

const MaterialsTab: FC<MaterialsTabProps> = ({ section }) => {
  const { slug } = useParams();
  const sectionInfo = useGetSectionsByCourse(slug, '', section);
  const [selectedSection, setSelectedSection] = useState('')
  const [selectedLecture, setSelectedLecture] = useState('');
  const sectionData = get(sectionInfo, 'data.data', [])

  const { completedContents, submittedAssignments } = useUserStore();

  const { t } = useTranslation()

  const [completedT] = ['completed', 'completion'].map(key => get(t('week'), key, ''))
  const [
    noContentHereT,
    downloadT,
    reTakeT,
    startT,
    submitT,
    submittedT
  ] = [
    'noContentHere',
    'download',
    'reTake',
    'start',
    'submit',
    'submitted'
  ].map(key => get(t('MaterialTab'), key, ''))
  const navigate = useNavigate()

  if (sectionInfo.isSuccess && sectionData.length === 0) {
    return <p className="text-sm md:text-base text-white">{noContentHereT}</p>
  }

  if (
    sectionInfo.isSuccess
    && selectedSection.length === 0
    && selectedLecture.length === 0
  ) {
    setSelectedSection(sectionData[0]?._id);
    setSelectedLecture(sectionData[0].lectures[0]._id)
  }

  if (sectionInfo.isLoading) {
    return <Loading />
  }

  const isAttachments = section === 'attachments'
  const isAssignment = section === 'assignment' 

  return <div className="text-white">
    {sectionData.map((eachSection: Section) => {
      return (
        <Collapsible
          open={true}
          onClick={() => {
            setSelectedSection(eachSection._id);
          }}
          className="cursor-pointer text-sm md:text-base"
          key={eachSection._id}
        >
          <CollapsibleContent className="">
            <div className={`flex items-center text-white`}>
              <p className="p-1 md:p-2 font-bold text-white">
                <span className="text-white">{eachSection.name}</span>
                {eachSection.lectures.every((lec) =>
                  lec.contents.every((content) =>
                    completedContents.includes(content._id)
                  )
                ) && <Badge className="bg-active-bg ml-4">{completedT}</Badge>}
              </p>
            </div>
            {eachSection.lectures.map((eachLectures: Lecture) => {
              return eachLectures.contents.length > 0 && (
                <Collapsible
                  key={eachLectures._id}
                  open={selectedSection === eachSection._id}
                  onClick={() => {
                    setSelectedLecture(eachLectures._id);
                  }}
                  className="cursor-pointer mx-1 md:mx-3"
                >
                  <CollapsibleContent className="">
                    <div className={`flex items-center text-white`}>
                      {selectedLecture === eachLectures._id ? <ChevronDown /> : <ChevronRight />}
                      <p className="p-2 font-bold text-white">{eachLectures.title}</p>
                    </div>
                    {eachLectures.contents.map((eachContents) => {
                      if(eachContents.type === 'assignment' && eachContents.attachments?.length === 0){
                        return
                      }
                      const [
                        readingT,
                        videoT,
                        quizT,
                        assignmentT,
                        pdfT
                      ] = [
                        `${eachContents.type}`,
                      ].map(key => get(t('manageCourses'), key, ''))
                      const [
                        subtitleT,
                      ] = [
                        'subtitle',
                      ].map(key => get(t('manageCourses'), key, ''))
                      const contentType = readingT || videoT || quizT || assignmentT || pdfT
                      return (
                        <Collapsible
                          open={selectedLecture === eachLectures._id}
                          className={`${selectedLecture === eachLectures._id && 'cursor-pointer border-b border-ol-warning '}`}
                          key={eachContents._id}
                        >
                          <CollapsibleContent className="ml-3 md:ml-10">
                            <div
                              className={`${!isAttachments && 'md:flex justify-between'} my-1 md:my-3`}
                            >
                              {!isAttachments && <p className="mx-3 p-2 break-words overflow-hidden">
                                <span className="font-bold capitalize">
                                  {contentType}:
                                </span>{' '}
                                {eachContents.title}
                              </p>}
                              {isAttachments
                                ? <>
                                  {
                                    (eachContents.type === 'video' || 
                                      eachContents.type === 'pdf' || 
                                      // eachContents.type === 'assignment' || 
                                      eachContents.type === 'notebook') && downloadableContent(eachContents, eachContents.type, contentType, downloadT)
                                    }
                                  {eachContents.type === 'video' && eachContents['subtitle'] && downloadableContent(eachContents, 'subtitle', subtitleT, downloadT)}
                                  {eachContents.attachments?.map((eachAttachment) => downloadableContent({...eachAttachment, title: eachContents.title}, '', contentType, downloadT))}
                                </>
                                : <Button className={`
                                  mr-4 py-0 ml-4 md:ml-0
                                  ${(isAttachments || completedContents.includes(eachContents._id)) && 'bg-ol-success'}
                                  hover:${(isAttachments || completedContents.includes(eachContents._id)) && 'bg-ol-success'}
                                  ${(isAssignment && submittedAssignments.includes(`${eachContents.assignment}`)) && 'bg-ol-success'}
                                  hover:${(isAssignment && submittedAssignments.includes(`${eachContents.assignment}`)) && 'bg-ol-success'}
                                `}
                                  onClick={() => {
                                    navigate(`/${slug}/content/${eachContents._id}`);
                                  }}>{
                                    isAssignment 
                                    ? submittedAssignments.includes(`${eachContents.assignment}`)
                                      ? submittedT 
                                      : submitT
                                    : completedContents.includes(eachContents._id) 
                                      ? reTakeT 
                                      : startT
                                  }</Button>}
                            </div>
                          </CollapsibleContent>
                        </Collapsible>
                      );
                    })}
                  </CollapsibleContent>
                </Collapsible>
              );
            })}
          </CollapsibleContent>
        </Collapsible>
      );
    })}
  </div>
}

export { downloadableContent, MaterialsTab }