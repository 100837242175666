import { FC, useState } from 'react';
import { toast } from '../use-toast';
import { useCreateFeedback, useUpdateFeedback, useDeleteFeedback, useReplyFeedback, useReactFeedback } from '@/hooks/feedback';
import { Delete, Edit2, ThumbsDown, ThumbsUp, Heart } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { PaginationEllipsis } from '../pagination';
import { FeedbackRes } from '@/types/feedback';
import { useUserStore } from '@/store/user';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';

interface FeedbackProps {
  feedbacks: FeedbackRes[] | []
  week: boolean
}

const Feedback: FC<FeedbackProps> = ({ feedbacks, week }) => {
  const { currentUser } = useUserStore();

  const { id } = useParams();

  const { t } = useTranslation()
  const [
    commentT,
    updateT,
    cancelT,
    replyT,
    editT,
    deleteT,
    unHideT,
    hideT,
    addACommentT
  ] = [
    'comment',
    'update',
    'cancel',
    'reply',
    'edit',
    'delete',
    'unHide',
    'hide',
    'addAComment'
  ].map(key => get(t('LectureContent'), key, ''))

  const [inputValue, setInputValue] = useState('');
  const [newComment, setNewComment] = useState('');
  const [replyContentId, setReplyContentId] = useState<string | null>(null);
  const [editFeedbackId, setEditfeedbackId] = useState<string>('');

  const createFeedback = useCreateFeedback();
  const updateFeedback = useUpdateFeedback();
  const replyFeedback = useReplyFeedback();
  const reactFeedback = useReactFeedback()
  const deleteFeedback = useDeleteFeedback();

  const editComment = (commentId: string) => {
    if (inputValue.length === 0) {
      toast({ title: 'Please fill a comment first' })
      return
    }

    updateFeedback.mutate({
      content: commentId,
      feedback: inputValue
    })
  }

  const addComment = (commentId: string | null) => {
    if (commentId ? inputValue.length === 0 : newComment.length === 0) {
      toast({ title: 'Please fill a comment first' })
      return
    }

    if ((commentId !== null) && inputValue) {
      replyFeedback.mutate({
        content: commentId,
        feedback: inputValue
      })
    } else {
      id && createFeedback.mutate({
        content: id?.toString(),
        feedback: newComment
      })
      setNewComment('')
    }
  }

  const resetAll = () => {
    setReplyContentId(null)
    setEditfeedbackId('')
    setInputValue('')
  }

  const UserCommentUI = (needCancelButton?: boolean, commentId?: string) => {
    return currentUser && <div className="flex gap-x-3 mt-5">
      <div className="w-[30px] h-[30px] border-2 border-gray-400 rounded-full relative overflow-hidden">
        <Avatar className="object-cover object-center w-full h-full">
          <AvatarImage src={currentUser.profilePic} alt="profile pic" />
          <AvatarFallback className="bg-transparent">
            <img
              src="/user-icon-placeholder-1.png"
              alt=""
              loading="lazy"
            />
          </AvatarFallback>
        </Avatar>
      </div>
      <div className='w-full'>
        <p className="font-medium leading-[17px] text-text">{currentUser.name}</p>
        <div className='flex flex-col items-end'>
          <input placeholder={addACommentT}
            value={commentId ? inputValue : newComment}
            onChange={(e) => commentId ? setInputValue(e.target.value) : setNewComment(e.target.value)}
            className='w-full mt-2 px-2 bg-transparent outline-none border-0 border-b text-white'
          />
          <div className='flex'>
            <Button
              variant="outline"
              className={`
              mt-2 px-3 py-1 text-text
              ${commentId
                  ? inputValue.length > 0 ? 'bg-accent' : 'bg-accent'
                  : newComment.length > 0 ? 'bg-accent' : 'bg-accent'
                } 
            `}
              onClick={() => {
                if (editFeedbackId && commentId) {
                  editComment(commentId)
                  resetAll()
                  return
                }
                addComment(replyContentId)
                resetAll()
              }}>{editFeedbackId ? updateT : commentT}</Button>
            {needCancelButton && <Button
              variant="destructive"
              className={`mt-2 px-3 py-1 bg-red-500 ml-4`}
              onClick={() => resetAll()}>{cancelT}</Button>}
          </div>
        </div>
      </div>
    </div>
  }

  const commentUI = (parentId: string, eachComment: FeedbackRes, level: number) => {
    return <div className={`${eachComment.hide && 'opacity-60'} my-3`}>
      <div className='flex'>
        <div className="w-[30px] h-[30px] border-2 border-gray-400 rounded-full relative overflow-hidden">
          <img
            src={
              eachComment.user?.avatar ||
              '/user-icon-placeholder-1.png'
            }
            alt="user profile pic"
            loading="lazy"
          />
        </div>
        <div className='mx-3 w-full'>
          <p className='font-medium leading-[17px] mt-1'>{eachComment.user?.name}</p> 
          {!eachComment.isDeleted && <div className='flex justify-between items-center mt-1'>
            <div className='flex'>
              <ThumbsUp fill={String(eachComment.user_react.includes('like') ? 'white' : 'none')} 
              className='ml-4 cursor-pointer'
                onClick={() => eachComment._id && reactFeedback.mutate({
                  content: eachComment._id,
                  type: 'like'
                })}
              />
              <p className='mx-3 pr-4 border-r border-ol-tertiary'>{eachComment.likes}</p>
              {/* <Smile 
                color={eachComment.user_react.includes('smile') ? 'black' : 'white'}
                fill={eachComment.user_react.includes('smile') ? '#fbdc34' : 'none'} 
                className='mr-4 cursor-pointer'
                onClick={() => eachComment._id && reactFeedback.mutate({
                  content: eachComment._id,
                  type: 'smile'
                })}
              /> */}
              <Heart 
                fill={eachComment.user_react.includes('heart') ? 'red' : 'none'}
                className='mr-4 cursor-pointer'
                onClick={() => eachComment._id && reactFeedback.mutate({
                  content: eachComment._id,
                  type: 'heart'
                })}
              />
              {/* <PartyPopper 
                color={eachComment.user_react.includes('popper') ? 'orange' : 'white'}
                fill={eachComment.user_react.includes('popper') ? 'yellow' : 'none'} 
                className='mr-4 cursor-pointer'
                onClick={() => eachComment._id && reactFeedback.mutate({
                  content: eachComment._id,
                  type: 'popper'
                })}
              /> */}
              <button
                className='ml-3'
                onClick={() => {
                  if (eachComment._id) {
                    setReplyContentId(parentId)
                    setInputValue(`@${eachComment.user?.name} `)
                  }
                }}
              >
                {replyT}
              </button>
            </div>
            
            {(currentUser?._id === eachComment.user?._id || currentUser?.role.toLocaleLowerCase() !== 'student') && !eachComment.isDeleted && <DropdownMenu>
              <DropdownMenuTrigger className='outline-none focus:ring-0'><PaginationEllipsis /></DropdownMenuTrigger>
              <DropdownMenuContent className='border border-ol-warning bg-ol-tertiary rounded z-20'>
                {
                  ((currentUser?.role.toLowerCase() !== 'student') ||
                  (currentUser?.role.toLowerCase() === 'student' && (!eachComment.isEdited || eachComment.editedBy?.role.toLowerCase() === 'student'))) &&
                    <DropdownMenuItem
                        className='px-4 py-2 cursor-pointer border-b border-ol-warning outline-none focus:ring-0'
                        onClick={() => {
                          if (eachComment._id) {
                            setEditfeedbackId(eachComment._id)
                            setInputValue(eachComment.feedback)
                          }
                        }}
                      >
                        {editT}
                    </DropdownMenuItem>
                  }
                <DropdownMenuItem
                  className='px-4 py-2 cursor-pointer outline-none focus:ring-0'
                  onClick={() => {
                    const answer = window.confirm('Are you sure want to delete the comment ?')
                    if (answer && eachComment._id) {
                      deleteFeedback.mutate(eachComment._id)
                    }
                  }}
                >
                  {deleteT}
                </DropdownMenuItem>
                {currentUser?.role.toLocaleLowerCase() !== 'student' && <DropdownMenuItem
                  className='px-4 py-2 cursor-pointer outline-none focus:ring-0 border-t border-ol-warning'
                  onClick={() => {
                    const answer = window.confirm(`Are you sure want to ${eachComment.hide ? 'unhide' : 'hide'} the comment ?`)
                    if (answer && eachComment._id) {
                      updateFeedback.mutate({
                        content: eachComment._id,
                        feedback: eachComment.feedback,
                        hide: eachComment.hide ? false : true
                      })
                      return
                    }
                  }}
                >
                  {eachComment.hide ? unHideT : hideT}
                </DropdownMenuItem>}
              </DropdownMenuContent>
            </DropdownMenu>}
          </div>}
        </div>
      </div>
      <div className='ml-12 mt-1'>
        {/* if edit feedback is true editing comment is priority else user can reply comment */}
        {
          editFeedbackId === eachComment._id
            ? <div>
              {UserCommentUI(true, eachComment._id)}
            </div>
            : <div>
              <p 
                style={{ verticalAlign: 'sub' }}
                className='flex'>
                {!eachComment.isDeleted && get(eachComment, 'masked_feedback', eachComment.feedback)}
                {!eachComment.isDeleted ? eachComment.isEdited ? (
                  <p className='flex italic items-center text-sm'>
                    <Edit2 className='ml-3 mr-1 h-3 w-3' /> {eachComment.hide ? 'hidden' : 'edited'} {eachComment.editedBy?._id === eachComment.user?._id ? null : ` by ${eachComment.editedBy?.role}`}
                  </p>
                ) : eachComment.isDeleted && eachComment.editedBy?._id !== eachComment.user?._id && (
                  <p className='flex italic items-center text-sm'>
                    <Delete className='ml-3 mr-1 h-3 w-3' /> deleted{` by ${eachComment.editedBy?.role}`}
                  </p>
                ) : <p className='flex italic items-center text-sm text-red-500'>
                  deleted{` by ${eachComment.deletedBy?.role}`}
                </p>}
              </p>
              {level <= 0 && eachComment.replies.map((eachReply: FeedbackRes) => {
                return commentUI(parentId, eachReply, 1)
              })}
            </div>
        }
        {eachComment._id === replyContentId && <div>
          {UserCommentUI(true, eachComment._id)}
        </div>}
      </div>
    </div>
  }

  return (
    <div className='mt-5 text-sm md:text-base'>
      <div className='flex justify-between items-center'>
        <p className='font-bold text-base lg:text-xl text-text'>{feedbacks.length} {commentT === 'Comment' ? `${commentT}s` : commentT}</p>
        {week && <div className='flex justify-between bg-ol-tertiary items-center rounded-xl py-2 text-white'>
          <ThumbsUp className='ml-4 cursor-pointer h-5 w-5 lg:h-6 lg:w-6' />
          <p className='text-white mx-3 pr-4 border-r border-ol-gray'>{feedbacks.length === 0 ? 0 : feedbacks[0]?.likes}</p>
          <ThumbsDown className='mr-4 cursor-pointer h-5 w-5 lg:h-6 lg:w-6' />
        </div>}
      </div>
      {currentUser && <> {UserCommentUI()}
        {feedbacks.length > 0 && feedbacks.map((eachComment: FeedbackRes) => {
          return eachComment._id && commentUI(eachComment._id, eachComment, 0)
        })}
      </>}

    </div>
  );
};

export default Feedback;