import { FC } from 'react'
import { useGetCategories } from '@/hooks/category'
import { useGetLevels } from '@/hooks/level'
import { Category } from '@/types/category'
import { Level } from '@/types/level'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Badge } from '@/components/ui/badge'

type Props = {
  selectedLevels: Level[], 
  setSelectedLevels: (level: Level[]) => void 
  selectedCategories: Category[], 
  setSelectedCategories: (category: Category[]) => void
}

const TagFilterSelector:FC<Props> = ({ selectedCategories, selectedLevels, setSelectedCategories, setSelectedLevels }) => {

  const { t } = useTranslation()

  const categoriesQuery = useGetCategories({ fullList: true })
  const categories: Category[] = get(categoriesQuery, 'data.data.data', [])

  const levelsQuery = useGetLevels({ fullList: true })
  const levels: Level[] = get(levelsQuery, 'data.data.data', [])

  const [
    allLevelsT,
    allCategoriesT,
  ] = [
    'allLevels',
    'allCategories'
    ].map((key) => get(t('courseFilters'), key, ''));

  function handleLevelSelect(level: Level) {
    const set = new Set([...selectedLevels])
    if (set.has(level)) {
      set.delete(level)
    } else {
      set.add(level)
    }

    setSelectedLevels(Array.from(set))
  }

  function handleCategorySelect(category: Category) {
    const set = new Set([...selectedCategories])
    if (set.has(category)) {
      set.delete(category)
    } else {
      set.add(category)
    }

    setSelectedCategories(Array.from(set))
  }

  return (
    <section>
      <div className="flex items-cneter justify-start flex-wrap gap-2 mt-5 lg:mb-3">
        <Badge 
          onClick={() => setSelectedLevels([])}
          className={`${selectedLevels.length === 0 ? 'bg-active-bg' : 'bg-primary'} rounded-full px-6 text-sm py-1 cursor-pointer focus:outline-none`}>{allLevelsT}</Badge>
        {levels.map((level) => (
          <Badge 
            key={level._id}
            onClick={() => handleLevelSelect(level)}
            className={`${selectedLevels.includes(level) ? 'bg-active-bg' : 'bg-primary'} px-3 py-1 rounded-full text-sm cursor-pointer focus:outline-none`}>{level.name}</Badge>
        ))}
      </div>
      <div className="flex items-cneter justify-start flex-wrap gap-2 mt-5 lg:mt-0">
        <Badge
          onClick={() => setSelectedCategories([])}
          className={`${selectedCategories.length === 0 ? 'bg-active-bg' : 'bg-primary'} rounded-full px-6 text-sm py-1 cursor-pointer focus:outline-none`}>{allCategoriesT}</Badge>
        {categories.map((category) => (
          <Badge 
            key={category._id}
            onClick={() => handleCategorySelect(category)}
            className={`${selectedCategories.includes(category) ? 'bg-active-bg' : 'bg-primary'} py-1 px-3 rounded-full text-sm cursor-pointer focus:outline-none`}>{category.name}</Badge>
        ))}
      </div>
    </section>
  )
}

export default TagFilterSelector