import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import '@/styles/index.css'
import '@/styles/globals.css'

export const toggleDarkMode = () => {
  const root = window.document.documentElement;
  const currentTheme = localStorage.getItem('kep-ol-theme');

  if (!currentTheme) {
    const isDarkMode = root.classList.toggle('dark');
    localStorage.setItem('kep-ol-theme', isDarkMode ? 'dark' : 'light');
  } else {
    if (currentTheme === 'dark') {
      root.classList.remove('dark');
      root.classList.add('light');
      localStorage.setItem('kep-ol-theme', 'light');
    } else {
      root.classList.remove('light');
      root.classList.add('dark');
      localStorage.setItem('kep-ol-theme', 'dark');
    }
  }
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
